<template>
    <v-container fluid class="mx-0 px-3">
        <v-card flat class="avsb-card-background">
            <v-card-title class="pl-1 py-2">Profile</v-card-title>
        </v-card>

        <v-card flat outlined>
            <v-card-text>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-tabs hide-slider>
                                <!-- <v-tab v-for="tab in tabs" :key="tab"> -->
                                <v-tab>
                                    <span class="avsb-tab-title">
                                        profile
                                    </span>
                                </v-tab>

                                <v-tab>
                                    <span class="avsb-tab-title">
                                        settings
                                    </span>
                                </v-tab>

                                <v-tab v-if="!isOAuthUser">
                                    <span class="avsb-tab-title">
                                        security
                                    </span>
                                </v-tab>

                                <!-- Profile Tab -->
                                <v-tab-item transition="false">
                                    <TabUserProfile />
                                </v-tab-item>

                                <!-- Settings Tab -->
                                <v-tab-item transition="false">
                                    <TabUserSettings />
                                </v-tab-item>

                                <!-- Security Tab -->
                                <v-tab-item transition="false">
                                    <TabUserSecurity />
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    components: {
        TabUserProfile: () => import("@/components/user/tabs/tabUserProfile"),
        TabUserSettings: () => import("@/components/user/tabs/tabUserSettings"),
        TabUserSecurity: () => import("@/components/user/tabs/tabUserSecurity"),
    },
    computed: {
        ...mapState("authsMgmt", ["user"]),
        isOAuthUser() {
            return this.user.type > 0;
        },
    },
    data: () => ({
        tabs: ["profile", "settings", "security"],
    }),
    methods: {
        ...mapActions("userSelfMgmt", ["retrieveSelf"]),
    },
    name: "Profile",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {
        this.retrieveSelf();
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
